export default {
  enter: 'Войти',
  yourCity: 'Ваш город',
  freeDelivery: 'Бесплатная доставка по Киеву',
  headerDeliveryValue: 'от 1000грн',
  today: 'Сегодня',
  logoText: 'Гипермаркет товаров <br> для животных',
  needConsult: 'Нужна консультация?',
  catalog: 'Каталог',
  items: ['Товар', 'Товара', 'Товаров'],
  reviews: ['Отзыв', 'Отзыва', 'Отзывов'],
  comments: ['Комментарий', 'Комментарий', 'Комментариев'],
  reviewsMany: 'Отзывы',
  categories: ['Категория', 'Категории', 'Категорий'],
  articles: ['Статья', 'Статьи', 'Статей'],
  days: ['День', 'Дня', 'Дней'],
  aSort: 'Сортировка',
  filters: 'Фильтры',
  done: 'Готово',
  sort: 'Сортировать',
  apply: 'Применить',
  and: 'и',
  close: 'Закрыть',
  popularCategories: 'Популярные категории',
  searchPlaceholder:
    'Здесь может быть ваш запрос, например: <span> Корм для собак</span>',
  searchPlaceholderMob: 'Я ищу...',
  leftReview: 'Оставить отзыв',
  available: 'В наличии',
  notAvailable: 'Нет в наличии',
  youChoose: 'Вы выбрали',
  clear: 'Очистить',
  price: 'Цена',
  brand: 'Бренд',
  brands: 'Бренды',
  all: 'Все',
  allCategories: 'Все категории',
  catalogItems: 'Каталог товаров',
  toCart: 'Купить',
  itemPiece: 'шт',
  goToMain: 'Вернуться на главную',
  categoryBrands: 'Бренды',
  chooseCategory: 'Выбрать категорию',
  totalBrands: 'Всего брендов',
  byLetter: 'По букве',
  byCategory: 'По категории',
  byList: 'Списком',
  byGrid: 'Плиткой',
  popular: 'Популярное',
  watchAll: 'Смотреть все',
  watchMore: 'Смотреть еще',
  otherArticles: 'Другие статьи',
  articleComments: 'Комментарии к статье',
  quickView: 'Быстрый просмотр',
  stockItems: 'Акционный товар',
  sellingHits: 'Хиты продаж',
  productsNew: 'Новинки',
  stocksMany: 'Акции',
  goToBrands: 'Вернуться к брендам',
  ends: 'Заканчивается',
  sku: 'Код товара',
  buyOneClick: 'Купить в 1 клик',
  findCheaper: 'Нашли дешевле? Снизим цену!',
  deliveryToCity: 'Доставка в город',
  free: 'Бесплатно',
  deliveryAndPayment: 'Доставка и оплата',
  aboutProduct: 'О товаре',
  description: 'Описание',
  characteristics: 'Характеристики',
  productRating: 'Рейтинг товара',
  pros: 'Преимущества',
  cons: 'Недостатки',
  showMore: 'Показать еще',
  showAll: 'Показать все',
  similarProducts: 'Похожие товары',
  otherProducts: 'Другие товары',
  economyTogether: 'Вместе экономнее!',
  watchedItems: 'Просмотренные товары',
  onSum: 'На сумму',
  articlesMany: 'Блог про домашних животных и зоотовары',
  readFull: 'Читать полностью',
  readAlso: 'Читайте также',
  shareArticle: 'Поделиться статьей',
  leftComment: 'Оставить комментарий',
  articleAnotation: 'Содержание статьи',
  oneClick: {
    title: 'Введите номер телефона',
    description: 'И мы Вам перезвоним для оформления заказа',
    btn: 'Жду звонка!',
  },
  productLabels: {
    topSale: 'Хит продаж',
    popular: 'Товар дня',
    new: 'Новинка',
  },
  economy: 'Экономия',
  phone: 'Телефон',
  searchByName: 'Поиск по названию',
  hereCanBeYourRequest: 'Здесь может быть ваш запрос, например Happy Baby',
  shockPrice: 'ШОК ЦЕНА',
  articlesSearch: 'Поиск по статьям',
  otherStocks: 'Другие акции',
  stockProducts: 'Акционные товары',
  yourCurrentOrder: 'Ваш текущий заказ',
  photo: 'Фото',
  name: 'Название',
  sum: 'Сумма',
  orderSum: 'Сумма заказа',
  totalToPay: 'Всего к оплате',
  sale: 'Скидка',
  continueShopping: 'Продолжить покупки',
  makeOrderOn: 'Оформить заказ на',
  personalData: 'Личные данные',
  personalDataDescription: 'Зарегистрированы на нашем сайте?',
  email: 'E-mail',
  personName: 'Имя',
  surname: 'Фамилия',
  orderComment:
    'Здесь вы можете указать особенности заказа, пожелания и другое...',
  iReceiver: 'Я получатель заказа',
  deliveryWayAndAdress: 'Способ и адрес доставки',
  deliveryWay: 'Способ доставки',
  wishDeliveryDate: 'Желаемая дата и время доставки',
  checkoutSelfDeliveryTooltip:
    'Дати отримання товару для кожного магазину в обраному місті вказанів блоці "З якого магазину забрати"',
  deliveryAddress: 'Адрес доставки',
  deliveryStreet: 'Улица',
  deliveryHouse: 'Дом',
  deliveryApartment: 'Квартира',
  deliveryApartmentShort: 'кв.',
  deliveryFloor: 'Этаж',
  deliveryFloorShort: 'эт.',
  deliveryBlock: 'Подьезд',
  deliveryBlockShort: 'под.',
  deliveryAddressKyiv: 'Бесплатная доставка по Киеву при заказе от 700 грн',
  paymentWay: 'Способ оплаты',
  recallToApprove: 'Перезвоните мне для уточнения деталей заказа',
  deliveryCost: 'Стоимость доставки',
  agreeWithContact: 'Я согласен с договором',
  publicOfferta: 'Публичной оферты',
  setupMain: 'Установить основным',
  main: 'Основной',
  itemsAdded: 'Добавлено товаров',
  clearList: 'Очистить список',
  show: 'Показывать',
  otherLists: 'Другие списки',
  compareList: 'Список сравнения',
  exit: 'Выйти',
  cancel: 'Отменить',
  approve: 'Подтвердить',
  yourGender: 'Ваш пол',
  gender: {
    male: 'Мужской',
    female: 'Женский',
  },
  dateOfBirth: 'Дата рождения',
  homeAnimals: 'Домашние животные',
  animals: {
    dog: 'Собака',
    cat: 'Кот',
    fish: 'Рыбки',
    bird: 'Птица',
    reptile: 'Рептилия',
    rodent: 'Грызун',
    hourse: 'Лошадь',
  },
  changePassword: 'Сменить пароль',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  passwordConfirm: 'Подтверждение пароля',
  deleteAccount: 'Удалить аккаунт',
  deleteAccountText:
    'Я осознанно удаляю свой аккаунт, списки желаний, историю просмотров и заказов. Отказываюсь от возможности получать письма о скидках и ответах на мои комментарии.',
  deleteCause: 'Опишите, пожалуйста, причину удаления аккаунта',
  oneMore: 'Еще один',
  onBonusBalance: 'На бонусном счету',
  availableToUse: 'Доступных для использования',
  willBeAvailableThrough: 'Cтанут доступны через',
  myOrders: 'Мои заказы',
  orderStatus: {
    completed: 'Выполнен',
    pending: 'Готовится к отправке',
    canceled: 'Отменен',
  },
  from: 'от',
  infoAboutOrder: 'Иформация про заказ',
  cnt: 'Кол-во',
  yourOrder: 'Ваш заказ',
  noOrders: {
    title: 'У вас еще нет заказов',
    description: 'Cамое время это изменить!',
  },
  noWatched: {
    title: 'У вас еще нет просмотренных товаров',
    description: 'Cамое время это изменить!',
  },
  noWishlist: {
    title: 'У вас еще нет товаров в списке желаний',
    description: 'Cамое время это изменить!',
  },
  payment: 'Оплата',
  status: 'Статус',
  total: 'Всего',
  wishList: 'Список желаний',
  totalProducts: 'Всего товаров',
  chooseAll: 'Выбрать все',
  removeSelect: 'Снять выделение',
  deleteChosen: 'Удалить выбранное',
  watchedProducts: 'Просмотренные товары',
  mailings: 'Рассылки',
  mailingsTypes: 'Виды рассылок',
  messagesSendByChannels: 'Сообщения отправляются по следующим каналам',
  toActiveBonusApprovePhone:
    'Для активации бонусного счета подтвердите актуальный номер телефона',
  smsWasSentOnGivenNumber:
    'На указанный номер будет выслано SMS с кодом подтверждения',
  changePhoneNumber: 'Сменить номер телефона',
  sendSms: 'Отправить SMS',
  savePhone: 'Сохранить телефон',
  onNumber: 'На номер',
  wasSentSmsWithCode: 'было выслано SMS с кодом',
  enterSmsCode: 'Введите код из SMS',
  codeFromSms: 'Код из SMS',
  bonusBill: 'Бонусный счет',
  spendBonuses: 'Потратить бонусы',
  getFirstBonuses: 'Получить первые бонусы',
  guest: 'Гость',
  dontHaveAccount: 'Нет Учетной записи?',
  createItRightNow: 'Создайте ее сейчас',
  login: {
    title: 'Добро пожаловать',
    description: 'Пожалуйста, введите для входа ваш Е-mail и пароль',
    loginPlaceholder: 'E-mail или телефон',
  },
  password: 'Пароль',
  forgotPassword: 'Забыли пароль',
  enterUsing: 'Войти с помощью',
  signup: {
    title: 'Регистрация',
    description: 'Пожалуйста, введите некоторую информацию',
  },
  restorePassword: {
    title: 'Восстановление пароля',
    description: 'Пожалуйста, введите ваш Е-mail',
    btn: 'Восстановить пароль',
  },
  register: 'Зарегистрироваться',
  IAgreeWithPolicy: ['Я согласен с', 'Политикой конфиденциальности'],
  viewAllInformation: 'Посмотреть всю информацию',
  footer: {
    copyright: 'Copyright © Zoo.com.ua, {date} | Все права защищены',
    questions: 'По всем вопросам',
  },
  createReview: {
    title: 'Создание отзыва',
    subTitle: 'Пожалуйста введите некотрые данные для создания отзыва',
    infoAboutEmail: 'Сообщить мне про ответы через e-mail',
    productComment: 'Ваш комментарий о товаре',
    addPhotos: 'Добавить фотографии',
    addPhotosDescription:
      'Перетяните файлы сюда, или нажмите на кнопку. Добавляйте **до 10 изображений** у форматах: **.jpg / .gif / .png**  размером файла **до 5 мб**',
  },
  reviewsText: ['Плохой', 'Так себе', 'Нормальный', 'Хороший', 'Прекрасный'],
  advantages: 'Преимущества',
  disadvantages: 'Недостатки',
  chooseFiles: 'Выбрать файлы',
  blog: 'Блог',
  blogSort: {
    newAsc: 'Сначала новые',
    newDesc: 'Сначала старые',
    quantityAsc: 'Больше всего просмотров',
    quantityDesc: 'Меньше всего просмотров',
  },
  brandsSclon: ['Бренд', 'Бренда', 'Брендов'],
  cart: 'Корзина',
  deliveryDepartment: 'Отделение',
  deliveryDepartmentOther: 'Желаемый способ и место доставки',
  deliveryDepartmentOtherShort: 'Способ и место доставки',
  errors: {
    required: 'Заполните это поле',
    phone: 'Неправильный формат телефона',
    email: 'Неправильный формат email',
    minLength: 'Минимальное количество символов',
    maxLength: 'Максимальное количество символов',
    mismatchPasswords: 'Пароли не совпадают',
    matchPasswords: 'Новый пароль должен отличаться от старого',
  },
  emailAlreadyTaken: 'Пользователь с таким email уже зарегистрирован',
  lettersFirstName: 'Имя должно содержать только буквы',
  lettersLastName: 'Фамилия должна содержать только буквы',
  invalidPassword: 'Неверный пароль',
  undefinedEmail: 'Не найден аккаунт с указанным email',
  invalidOldPassword: 'Неверно введен старый пароль',
  sessionExpired: 'Время сессии истекло',
  sortItems: {
    default: 'По умолчанию',
    'products-date-asc': 'Сначала старые',
    'products-date-desc': 'Сначала новые',
    'products-price-asc': 'По возрастанию цены',
    'products-price-desc': 'По убыванию цены',
  },
  page404: {
    name: 'Страница не найдена',
    title: 'Упс, мы тут немного заигрались, и не создали эту страницу :(',
    subtitle:
      'Либо ее и не должно было существовать, пожалуйста попробуйте поискать еще раз',
    btn: 'Перейти на главную',
  },
  addedToCart: 'добавлен в корзину',
  makeOrder: 'Оформить заказ',
  resultOrder: {
    success: {
      name: 'Спасибо за заказ',
      title: 'Ваш заказ принят',
      subtitle: 'Ваш заказ принят в работу. В ближайшее время менеджер с Вами свяжется.',
      btn: 'Перейти на главную',
    },
    checking: {
      name: 'Пожалуйста, подождите',
      title: 'Происходит проверка платежа',
      subtitle: 'Ваш заказ в обработке, это длится до нескольких минут',
      btn: 'Перейти на главную',
    },
    failure: {
      name: 'Произошла ошибка',
      title: 'Ошибка! Ваш заказ не принят',
      subtitle: 'Ваш заказ не принят. Вы можете попробовать еще раз или сделать заказ набрав нас по номеру телефона',
      btn: 'Перейти на главную',
    }
  },
  yourOrderNumber: 'Ваш номер заказа',
  checkoutEmpty: {
    title: 'Ваша корзина пуста ',
    description:
      'К сожалению, Ваша корзина пуста. Перейдите на главную страницу для оформления заказа.',
    btn: 'Перейти на главную',
  },
  deliveryInFastTime: 'В ближайшее время',
  inWorkTime: 'В рабочее время',
  tag: 'Тег',
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  noReviewsPlaceholder: 'Добавление новых отзывов пока недоступно',
  loginBeforeSendReview: 'Что бы оставить отзыв авторизуйтесь пожалуйста',
  language: 'Язык',

  cheaperModal: {
    title: 'Нашли дешевле? <span>Получите скидку!</span>',
    subtitle:
      'Отправьте ссылку на товар, и мы предоставим вам скидку (НУЖЕН ТЕКСТ)',
  },
  ok: 'Ок',

  productUrl: 'Ссылка на товар',
  consultModal: {
    title: 'Нужна консультация?',
    subtitle:
      'Заполните краткую форму, и наш менеджер ответит вам в ближайшее время',
  },
  consultThanksModal: {
    title: 'Спасибо за заявку!',
    description: '',
  },
  cheaperThanksModal: {
    title: 'Спасибо за заявку!',
    description: '',
  },
  oneClickThanks: {
    title: 'Спасибо за заявку!',
    description: '',
  },
  availableThanksModal: {
    title: 'Спасибо за заявку!',
    description: '',
  },
  reviewThanksModal: {
    title: 'Спасибо за отзыв!',
    description:
      'Ваш отзыв отправлен на модерацию, если в нем нет нарушений правил публикации, он будет опубликован в ближайшее время.',
  },

  yourComment: 'Ваш комментарий',
  sendRequest: 'Отправить заявку',
  linkToProduct: 'Ссылка на товар',
  callWhenAvailable: 'Под заказ',
  availableModal: {
    title: 'Сообщить о поставке',
    subtitle: '',
  },
  cityModal: {
    title: 'Выбор города',
    description:
      'При выборе г.Киев вам предоставляется <br> <span>бесплатная доставка</span> при заказе от 1000грн.',
  },
  cities: {
    kiev: 'Киев',
    other: 'Другой город',
  },
  city: 'Город',
  // новое
  linkCopied: 'Ссылка скопирована в буфер обмена',
  cabinetMenu: {
    personalData: 'Личные данные',
    orders: 'Мои заказы',
    reviews: 'Мои отзывы',
    wishlist: 'Список желаний',
    watched: 'Просмотренные товары',
    mailings: 'Рассылки',
    bonus: 'Бонусный счет',
  },
  search: 'Поиск',
  incognita: 'Инкогнито',
  watched: 'Просмотренные',
  noDescription: "Нет описания",
  contacts: "Контакты",
  information: "Информация",
  listSearch: "Поиск по списку",
  successDataEdit: 'Данные обновлены',
  errorDataEdit: 'Ошибка редактирования',
  welcome: 'Добро пожаловать',
  successSignUp: 'Регистрация прошла успешно, пожалуйста авторизируйтесь',
  successRestorePassword: 'Новый пароль отправлен на ваш e-mail, пожалуйста проверьте почту',
  error: 'Ошибка',
  serverError: 'Ошибка сервера',
  kyiv: 'Киев',
  nothingNoMatch: 'Ничего не найдено',
  titleH1: 'Интернет-магазин товаров для животных',
  labelPrice: 'Цена:',
  headerMainPage: 'Интернет-магазин товаров для животных',
  faq: {
    contacts: 'контакты',
    title: 'Часто задаваемые вопросы (FAQ)',
    question1: 'Какой товар пользуется самой большой популярностью?',
    answer1:
      'Самым популярным товаром в разделе {currentCategory} на данный момент является (название с ссылкой на товар ТОП-1).',
    question2: 'ТОП-5 товаров {currentCategory} по версии покупателей ZOO.com.ua.',
    answer2:
      'ТОП-5 товаров из раздела {currentCategory} среди покупателей являются: (название и ссылка на товар ТОП-1), (название и ссылка на товар ТОП-2), (название и ссылка на товар ТОП-3), (название и ссылка на товар ТОП-4), (название и ссылка на товар ТОП-5).',
    question3: 'Какая цена минимальная на товары {currentCategory}?',
    answer3: 'Стоимость товаров из раздела {currentCategory} от (минимальная цена товара раздела).',
    question4: 'Как заказать товары из раздела {currentCategory}?',
    answer4:
      'Заказать товар вы можете по телефону или воспользовавшись карзиной. Также если у вас есть какие-либо вопросы или предложения, позвоните нам по указанным номерам телефонов или же напишите нам на e-mail или viber на странице ',
  },
  page: 'Страница',
}
